import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import ReCAPTCHA from 'react-google-recaptcha';

const BookingForm2 = () => {
  const cup = "6Ldt6ycqAAAAAKnQaYH2k2Efr3yaKPLN-OuxJEpK"
  const [isSubmitted, setIsSubmitted] = useState(false); // State to track form submission
  const navigate = useNavigate(); // Hook for navigation in React Router

  // Initial form state
  const [formData, setFormData] = useState({
    course: '', // Initial value for course
    guardianName: '',
    childName: '',
    email: '',
    message: ''
  });

  //console.log('courseId:', courseId);

  const [captchaToken, setCaptchaToken] = useState(null); // State to hold the reCAPTCHA token
  const [errorMessage, setErrorMessage] = useState(''); // State to hold error messages

  const handleCaptchaChange = (token) => {
    setCaptchaToken(token);
    setErrorMessage(''); // Clear any previous error messages when captcha is successfully completed
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

      try {

        const token = localStorage.getItem('token');
        await axios.post('http://89.58.35.184:3000/api/bookForms/addBookForm', formData, {
          headers: {
            Authorization: `Bearer ${token}`
          }
        });
        setIsSubmitted(true);
    } catch (error) {
        console.error('Error adding book form:', error);
    }

    // Validate reCAPTCHA
    if (!captchaToken) {
      setErrorMessage('Bitte bestätigen Sie, dass Sie kein Roboter sind.');
      return;
    }

    // Validate course selection
    if (!formData.course) {
      setErrorMessage('Bitte wählen Sie einen Kurs aus.');
      return;
    }

    console.log('Form submitted:', formData);
    setIsSubmitted(true); // Set submission state to true
    
  };

  useEffect(() => {
    if (isSubmitted) {
      const timer = setTimeout(() => {
        window.location.href = '/'; // Redirect to the home page after 5 seconds
      }, 5000); // 5000 milliseconds = 5 seconds

      // Cleanup the timeout if the component is unmounted or if isSubmitted changes
      return () => clearTimeout(timer);
    }
  }, [isSubmitted]);

  if (isSubmitted) {
    return (
      <div className="min-h-screen flex items-center justify-center mt-20 md:-mt-40">
      <div className="bg-white p-10 rounded-md shadow-md mx-auto text-center">
        <div className="rounded-full h-52 w-52 md:h-[140px] md:w-[140px] bg-[#F8FAF5] flex items-center justify-center mx-auto mb-6">
          <i className="checkmark text-[#9ABC66] text-[100px] md:text-[100px] leading-[200px]">✓</i>
        </div>
        <h1 className="text-[#88B04B] font-nunito font-extrabold text-[18px] md:text-[24px] mb-2">Sie haben unseren Kurs erfolgreich angefragt</h1>
        <p className="text-[#404F5E] font-nunito text-[14px] m-0">
          In Kürze erhalten Sie eine E-Mail mit Informationen bezüglich der Aufnahme und ggf. eine Bestätigung zur erfolgreichen Anmeldung. <br /> Vielen Dank für Ihr Interesse.
        </p>
      </div>
    </div>
    );
  }

  return (
    <div className="grid grid-cols-1 pt-28 md:grid-cols-2 gap-8">
      <div>
        <h2 className="text-2xl font-semibold mb-4">Jetzt Platz anfragen</h2>
        <p className="mb-4 md:max-w-md">
          Fragen Sie gerne über unser Formular einen Platz für den gewünschten Kurs und Zeitraum an. Wir werden uns sobald wie möglich bei Ihnen melden und Ihnen mitteilen, ob Sie den Platz erhalten haben. Nach erfolgreichem Absenden Ihrer Nachricht erhalten Sie eine Eingangsbestätigung an Ihre angegebene E-Mail-Adresse. Schauen Sie ggf. auch in Ihren Spam-Ordner.
        </p>

      </div>
      <form onSubmit={handleSubmit} className="bg-white p-8 rounded-lg shadow-md space-y-4">
        <div>
          <label htmlFor="course" className="block font-semibold">Kurs auswählen *</label>
          <select id="course" name="course" value={formData.course} onChange={handleChange} className="border-2 w-full h-10 bg-gray-200 py-2 px-4 rounded-[2%]" required>
            <option value="">Wählen Sie einen Kurs aus</option>
            <option value="04.08.2025 - 08.08.2025: Expertenkurs (Python)">04.08.2025 - 08.08.2025: Expertenkurs (Python)</option>
            <option value="11.08.2025 - 15.08.2025: Expertenkurs (Python)">11.08.2025 - 15.08.2025: Expertenkurs (Python)</option>
          </select>
        </div>
        <div>
          <label htmlFor="guardianName" className="block font-semibold">Name des Erziehungsberechtigten *</label>
          <input type="text" id="guardianName" name="guardianName" value={formData.guardianName} onChange={handleChange} className="w-full border-gray-200 border-2 rounded-md p-2" required />
        </div>
        <div>
          <label htmlFor="childName" className="block font-semibold">Name des Kindes *</label>
          <input type="text" id="childName" name="childName" value={formData.childName} onChange={handleChange} className="w-full border-gray-200 border-2 rounded-md p-2" required />
        </div>
        <div>
          <label htmlFor="email" className="block font-semibold">E-Mail *</label>
          <input type="email" id="email" name="email" value={formData.email} onChange={handleChange} className="w-full border-gray-200 border-2 rounded-md p-2" required />
        </div>
        <div>
          <label htmlFor="message" className="block font-semibold">Nachricht</label>
          <textarea id="message" name="message" value={formData.message} onChange={handleChange} rows="4" className="w-full border-gray-200 border-2 rounded-md p-2"></textarea>
        </div>
        <div>
          <ReCAPTCHA
            sitekey={cup} // Replace with your actual site key
            onChange={handleCaptchaChange}
          />
        </div>
        {errorMessage && (
          <div className="text-red-500 font-semibold">{errorMessage}</div>
        )}
        <div className="flex items-center space-x-2">
          <input type="checkbox" id="agreement" name="agreement" required />
          <label htmlFor="agreement" className="text-sm">Sie erklären sich damit einverstanden, dass Ihre Daten zur Bearbeitung Ihres Anliegens verwendet werden. Weitere Informationen und Widerrufshinweise finden Sie in unserer <a href='' className='text-blue-400'>Datenschutzerklärung</a> .</label>
        </div>
        <button type="submit" className="bg-[#003566] text-white py-2 px-4 rounded-md transition-colors">Absenden</button>
      </form>
    </div>
  );
};

export default BookingForm2;
