import React, { useState, useEffect } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';

const BookingForm = () => {
  const [courses, setCourses] = useState([]);
  const [formData, setFormData] = useState({
    course: '',
    guardianName: '',
    childName: '',
    email: '',
    message: '',
  });
  const [captchaToken, setCaptchaToken] = useState(null);
  const [errorMessage, setErrorMessage] = useState('');
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isWaitlisted, setIsWaitlisted] = useState(false);

  const API_URL = "https://verwaltung.informatik-hilfe.de/api";
  const API_KEY = "ba55a0e1265dbbf536b33d1c0a5e0859294bb630d8924b88efb6f05fb795adb3";

  // Fetch courses from the API
  const fetchCourses = async () => {
    try {
      const response = await fetch(`${API_URL}/courses`, {
        headers: {
          Authorization: `Bearer ${API_KEY}`,
        },
      });
      if (!response.ok) throw new Error('Fehler beim Abrufen der Kurse.');
      const data = await response.json();
      setCourses(data);
    } catch (error) {
      setErrorMessage('Kurse konnten nicht geladen werden. Bitte versuchen Sie es später.');
      console.error(error);
    }
  };

  // Check course capacity
  const checkCapacity = async (courseId) => {
    try {
      const response = await fetch(`${API_URL}/check-capacity/${courseId}`, {
        headers: {
          Authorization: `Bearer ${API_KEY}`,
        },
      });
      if (!response.ok) throw new Error('Kapazität konnte nicht überprüft werden.');
      return await response.json();
    } catch (error) {
      console.error(error);
      throw error;
    }
  };

  // Helper function to handle API POST requests
  const postToApi = async (url, data) => {
    try {
      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${API_KEY}`,
        },
        body: JSON.stringify(data),
      });
      if (!response.ok) {
        const errorText = await response.text();
        console.error(`Error: ${response.status} ${response.statusText}`, errorText);
        throw new Error('API-Anfrage fehlgeschlagen.');
      }
      return await response.json();
    } catch (error) {
      console.error(error);
      throw error;
    }
  };

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!captchaToken) {
      setErrorMessage('Bitte bestätigen Sie, dass Sie kein Roboter sind.');
      return;
    }

    if (!formData.course) {
      setErrorMessage('Bitte wählen Sie einen Kurs aus.');
      return;
    }

    try {
      const capacity = await checkCapacity(formData.course);

      if (!capacity.available) {
        // Add to waiting list
        const waitlistResponse = await postToApi(`${API_URL}/courses/${formData.course}/waiting-list`, {
          parentName: formData.guardianName,
          childName: formData.childName,
          email: formData.email,
          message: formData.message,
        });
        setIsWaitlisted(true); // Benutzer ist auf der Warteliste
        setErrorMessage(`Kurs voll. Wartelistenposition: ${waitlistResponse.position}.`);
      } else {
        // Register participant
        setIsWaitlisted(false); // Benutzer erfolgreich registriert
        await postToApi(`${API_URL}/courses/${formData.course}/register`, {
          parentName: formData.guardianName,
          childName: formData.childName,
          email: formData.email,
          message: formData.message,
        });
        setErrorMessage('');
      }

      setIsSubmitted(true);
    } catch (error) {
      setErrorMessage(error.message || 'Ein Fehler ist aufgetreten.');
    }
  };

  useEffect(() => {
    fetchCourses();
  }, []);

  if (isSubmitted) {
    return (
      <div className="min-h-screen flex items-center justify-center mt-20 md:-mt-40">
        <div className="bg-white p-10 rounded-md shadow-md text-center">
          <h1 className="text-green-600 font-bold text-xl mb-2">
            {isWaitlisted
              ? 'Auf Warteliste eingetragen!'
              : 'Anfrage erfolgreich gesendet!'}
          </h1>
          <p>
            {isWaitlisted
              ? 'Der Kurs ist voll. Sie wurden erfolgreich auf die Warteliste gesetzt. Wir werden uns bei Ihnen melden, sobald ein Platz verfügbar wird.'
              : 'Vielen Dank für Ihre Anmeldung. Wir werden uns in Kürze bei Ihnen melden.'}
          </p>
        </div>
      </div>
    );
  }

  return (
    <div className="grid grid-cols-1 pt-28 md:grid-cols-2 gap-8">
      <div>
        <h2 className="text-2xl font-semibold mb-4">Jetzt Platz anfragen</h2>
        <p className="mb-4 md:max-w-md">
          Fragen Sie gerne über unser Formular einen Platz für den gewünschten Kurs und Zeitraum an. Wir werden uns sobald wie möglich bei Ihnen melden und Ihnen mitteilen, ob Sie den Platz erhalten haben. Nach erfolgreichem Absenden Ihrer Nachricht erhalten Sie eine Eingangsbestätigung an Ihre angegebene E-Mail-Adresse. Schauen Sie ggf. auch in Ihren Spam-Ordner.
        </p>

      </div>
      <form onSubmit={handleSubmit} className="bg-white p-8 rounded-lg shadow-md space-y-4">
        <h2 className="text-2xl font-semibold mb-4">Jetzt Platz anfragen</h2>

        <div>
          <label htmlFor="course" className="block font-semibold">
            Kurs auswählen *
          </label>
          <select
            id="course"
            name="course"
            value={formData.course}
            onChange={(e) => setFormData({ ...formData, course: e.target.value })}
            className="border-2 w-full bg-gray-200 py-2 px-4 rounded-md"
          >
            <option value="">Bitte wählen Sie einen Kurs</option>
            {courses.map((course) => (
              <option key={course.id} value={course.id}>
                {course.name} - {new Date(course.date).toLocaleDateString()}
              </option>
            ))}
          </select>
        </div>

        <div>
          <label htmlFor="guardianName" className="block font-semibold">
            Name des Erziehungsberechtigten *
          </label>
          <input
            type="text"
            id="guardianName"
            name="guardianName"
            value={formData.guardianName}
            onChange={(e) => setFormData({ ...formData, guardianName: e.target.value })}
            className="border-2 w-full rounded-md p-2"
            required
          />
        </div>

        <div>
          <label htmlFor="childName" className="block font-semibold">
            Name des Kindes *
          </label>
          <input
            type="text"
            id="childName"
            name="childName"
            value={formData.childName}
            onChange={(e) => setFormData({ ...formData, childName: e.target.value })}
            className="border-2 w-full rounded-md p-2"
            required
          />
        </div>

        <div>
          <label htmlFor="email" className="block font-semibold">
            E-Mail *
          </label>
          <input
            type="email"
            id="email"
            name="email"
            value={formData.email}
            onChange={(e) => setFormData({ ...formData, email: e.target.value })}
            className="border-2 w-full rounded-md p-2"
            required
          />
        </div>

        <div>
          <label htmlFor="message" className="block font-semibold">
            Nachricht
          </label>
          <textarea
            id="message"
            name="message"
            value={formData.message}
            onChange={(e) => setFormData({ ...formData, message: e.target.value })}
            rows="4"
            className="border-2 w-full rounded-md p-2"
          />
        </div>

        <div>
          <ReCAPTCHA
            sitekey="6Ldt6ycqAAAAAKnQaYH2k2Efr3yaKPLN-OuxJEpK"
            onChange={(token) => setCaptchaToken(token)}
          />
        </div>

        {errorMessage && <div className="text-red-500">{errorMessage}</div>}

        <button type="submit" className="bg-blue-600 text-white py-2 px-4 rounded-md">
          Absenden
        </button>
      </form>
    </div>
  );
};

export default BookingForm;
