import React, { useState, useEffect } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import { MdOutlineMailOutline, MdAddLocationAlt } from "react-icons/md";
import { SiOpenstreetmap } from "react-icons/si";
import axios from 'axios';


const Contact = () => {
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    message: '',
  });

  const [captchaToken, setCaptchaToken] = useState(null);
  const [errorMessage, setErrorMessage] = useState('');
  const [isSubmitted, setIsSubmitted] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    /*if (!captchaToken) {
      setErrorMessage('Bitte bestätigen Sie, dass Sie kein Roboter sind.');
      return;
    }*/

    try {

      const token = localStorage.getItem('token');
      await axios.post('http://89.58.35.184:3000/api/contacts/addContact', formData, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      setIsSubmitted(true);
  } catch (error) {
      console.error('Error adding book form:', error);
  }
  finally {
    setIsSubmitted(true);

  }

    console.log('Formular abgeschickt:', formData);
    setIsSubmitted(true);
  };

  useEffect(() => {
    if (isSubmitted) {
      const timer = setTimeout(() => {
        window.location.href = '/'; // Redirect to the home page after 5 seconds
      }, 5000); // 5000 milliseconds = 5 seconds

      // Cleanup the timeout if the component is unmounted or if isSubmitted changes
      return () => clearTimeout(timer);
    }
  }, [isSubmitted]);

  const handleCaptchaChange = (token) => {
    setCaptchaToken(token);
    setErrorMessage('');
  };

  if (isSubmitted) {
    return (
      <div className="min-h-screen flex items-center justify-center mt-20 md:-mt-40">
        <div className="bg-white p-10 rounded-md shadow-md mx-auto text-center">
          <div className="rounded-full h-52 w-52 md:h-[140px] md:w-[140px] bg-[#F8FAF5] flex items-center justify-center mx-auto mb-6">
            <i className="checkmark text-[#9ABC66] text-[100px] md:text-[100px] leading-[200px]">✓</i>
          </div>
          <h1 className="text-[#88B04B] font-nunito font-extrabold text-[18px] md:text-[24px] mb-2">Sie haben uns Ihre Daten erfolgreich mitgeteilt</h1>
          <p className="text-[#404F5E] font-nunito text-[14px] m-0">
            Wir kontaktieren Sie bald möglich. <br /> Vielen Dank für Ihr Interesse.
          </p>
        </div>
      </div>
    );
  }

  return (
    <div id='Kontakt' className='bg-[#edede9] mx-auto md:p-10'>
      <div className="container mx-auto flex flex-col md:flex-row pt-20 items-center py-2 max-w-screen-xl justify-between px-4">
        <div className="flex flex-col w-full md:w-[40%] mb-8 md:mb-0">
          <h2 className="text-3xl font-bold mb-4">Kontaktieren Sie uns</h2>
          <p className="mb-2 max-w-md">Sie benötigen weitere Informationen oder haben ein anderes Anliegen? Bitte schicken Sie uns eine E-Mail oder
             verwenden Sie das Kontaktformular.
          </p>
          <h3 className="text-lg font-semibold mb-2">Adresse</h3>
          <div className="mb-2 flex items-center"><MdOutlineMailOutline size={24} color='#8d99ae' /> <p className='pl-1'>E-Mail: fbi-kindercampus@gmx.de</p></div>
          <div className="mb-2 flex items-center"><SiOpenstreetmap size={24} color='#8d99ae' /> <p className='pl-1'>Straße: Birkenweg 7</p></div>
          <div className="mb-2 flex items-center"><MdAddLocationAlt size={24} color='#8d99ae' /> <p className='pl-1'>Ort: 64295 Darmstadt</p></div>
        </div>
        <div className="w-full md:w-[40%] flex justify-center">
          <form onSubmit={handleSubmit} className="w-full max-w-md bg-white p-6 rounded-lg shadow-2xl">
            <h2 className="text-3xl font-bold mb-4">Kontakt Aufnehmen</h2>
            <p className='mb-4 text-gray-400'>Bitte füllen Sie das Kontaktformular vollständig aus.</p>
            <div className="flex flex-wrap mb-4">
              <div className="w-full md:w-1/2 mb-4 md:mb-0">
                <input
                  type="text"
                  name="firstName"
                  placeholder="Vorname"
                  value={formData.firstName}
                  onChange={handleChange}
                  className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                />
              </div>
              <div className="w-full md:w-1/2 pl-1">
                <input
                  type="text"
                  name="lastName"
                  placeholder="Nachname"
                  value={formData.lastName}
                  onChange={handleChange}
                  className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                />
              </div>
            </div>
            <div className="mb-4">
              <input
                type="email"
                name="email"
                placeholder="Ihre E-Mail-Adresse"
                value={formData.email}
                onChange={handleChange}
                className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
              />
            </div>
            <div className="mb-4">
              <input
                type="tel"
                name="phone"
                placeholder="Telefonnummer"
                value={formData.phone}
                onChange={handleChange}
                className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
              />
            </div>
            <div className="mb-4">
              <textarea
                name="message"
                placeholder="Wie können wir Ihnen helfen?"
                value={formData.message}
                onChange={handleChange}
                className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                rows="4"
              />
            </div>
           
            <button type="submit" className="w-full bg-[#003566] text-white py-2 rounded hover:bg-blue-700 transition duration-200">Absenden</button>
          </form>
        </div>
      </div>
      <div className='pb-10'></div>
    </div>
  );
};

export default Contact;
