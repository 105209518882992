import React from 'react';
import { Link } from 'react-router-dom';
import data from '../data';
import profile from '../assets/images/profile.png';

const Testimonial = () => {
  const { testimonials } = data;

  return (
      <div id='testimonials' className='max-w-6xl mx-auto pb-16 pt-28'>

      <div className='px-5   text-center'>
      <p className="text-blue-400 text-lg">REZENSIONEN</p>

        <h2 className='text-4xl font-bold text-center'>
          Was Teilnehmer*innen denken
        </h2>
        <p className="mx-auto max-w-lg text-gray-500 text-center pt-6 font-light ">
        Das Feedback unserer Teilnehmer*innen ist uns sehr wichtig, um unsere Kurse stetig verbessern zu können. Nachfolgend drei repräsentative Stimmen.                   </p>
        <div className='flex flex-col mt-28 space-y-10 md:space-y-0 md:grid md:grid-cols-3 md:gap-6'>
          {testimonials.map((testimonial, index) => (
            <div
              key={index}
              className='flex flex-col items-center p-6 space-y-6 rounded-lg bg-veryLightGray'
            >
              <img src={profile} className='w-16 -mt-14' alt='' />
              <h5 className='text-lg font-bold'>{testimonial.name}</h5>
              <p className='text-md text-darkGrayishBlue'>{testimonial.text}</p>
            </div>
          ))}
      </div>
    
      </div>
      </div>

  );
};

export default Testimonial;
