import React, {useEffect, useState} from 'react';
import Navbar from '../components/Navbar';
import Hero from '../components/Hero';
import Features from '../components/Features.jsx';
import Testimonial from '../components/Testimonial';
import CallToAction from '../components/CallToAction';
import Footer from '../components/Footer';
import FAQ_State from '../components/faq.jsx';
import Contact from '../components/contact.jsx';
import Courses from '../components/courses.jsx';
import axios from "axios";
import {useNavigate} from "react-router-dom";

const Page_1 = () => {

    
  return (
    <div className=''>
      <Navbar />
      <section id="hero">
      <Hero />
      </section>
      <section id="ueberuns">
    
      <Features />

      </section>
      <section id="courses">
        <Courses />
      </section>
      <section id="faq">
        <FAQ_State />
      </section>
      <section id="rezensionen">
        <Testimonial />
      </section>
      <section id="kontakt">
        <Contact />
      </section>
      <Footer />
    </div>
  );
}

export default Page_1;
